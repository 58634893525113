import "./Payment-info.css";

function PaymentInfo() {
  return (
    <section className="payment--section">
      <h2>Coming Soon...!</h2>
    </section>
  );
}

export default PaymentInfo;
