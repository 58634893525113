export const Partners = [
  {
    id: 0,
    img: "./images/partners/partner1.png",
  },

  {
    id: 1,
    img: "./images/partners/partner2.png",
  },

  {
    id: 2,
    img: "./images/partners/partner3.png",
  },

  {
    id: 3,
    img: "./images/partners/partner4.png",
  },
];
