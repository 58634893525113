export const featuredmodel = [
  {
    id: 1,
    category: "Commercial",
  },

  {
    id: 2,
    category: "Fashion",
  },

  {
    id: 3,
    category: "Child",
  },

  {
    id: 4,
    category: "Fitness",
  },

  {
    id: 5,
    category: "Runway",
  },

  {
    id: 6,
    category: "Promotional",
  },
];
