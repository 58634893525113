const TestimonialApi = [
  {
    id: 1,
    image: "./images/client/client2.jpg",
    design: "Mevine Design",
    name: "Joy Chidinma",
    offcer: "Graphic Designer",
    post: "Student - Uniben",
    date: "Thoda Department -Jan 4, 2023 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },

  {
    id: 2,
    image: "./images/client/client3.png",
    design: "Mevine Design",
    name: "Adenike Grace",
    offcer: "Model",
    post: "Forewall - Magazine",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },

  {
    id: 3,
    image: "./images/client/client2.jpg",
    design: "Mevine Design",
    name: "Stephenie Daniels",
    offcer: "Web Developer",
    post: "CEO - Marketing",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },

  {
    id: 4,
    image: "./images/client/client2.jpg",
    design: "Mevine Design",
    name: "Daniel Fajuyi",
    offcer: "Web Developer",
    post: "CEO - Marketing",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },

  {
    id: 5,
    image: "./images/client/client2.jpg",
    design: "Mevine Design",
    name: "Daniel Fajuyi",
    offcer: "Web Developer",
    post: "CEO - Marketing",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },

  {
    id: 6,
    image: "./images/client/client3.png",
    design: "Mevine Design",
    name: "Daniel Fajuyi",
    offcer: "Web Developer",
    post: "CEO - Marketing",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "The premieum model app is the best app in the universe, i dont know what to say more than that",
  },
];

export default TestimonialApi;
