export const navList1 = ["about", "jobs", "email/pass"];
export const navList2 = ["payment", "dashboard"];
export const SocialMedia = [
  //   {
  //     "type": "text",
  //     "id": "facebook",
  //     "placeholder": "Facebook...",
  //     "label": "Facebook"
  //   },
  {
    type: "text",
    id: "instagram",
    placeholder: "Ig handle...",
    label: "Instagram",
  },
  //   {
  //     "type": "text",
  //     "id": "twitter",
  //     "placeholder": "Twitter...",
  //     "label": "Twitter"
  //   }
];

export const Photo = [
  {
    id: "photo1",
  },
  {
    id: "photo2",
  },
  {
    id: "photo3",
  },
  {
    id: "photo4",
  },
  {
    id: "photo5",
  },
  {
    id: "photo6",
  },
];
