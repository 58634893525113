export const LatestNews = [
  {
    id: 1,
    img: "./images/news/news1.jpg",
    title: "Premium model agency webapp is the best models webapp in the world",
    category: "modelling",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },

  {
    id: 2,
    img: "./images/news/news2.png",
    title: "The Making of Premium Models for brands",
    category: "Brands",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },

  {
    id: 3,
    img: "./images/news/news3.jpg",
    title: "Premium model agency webapp is the best models webapp in the world",
    category: "culture",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },

  {
    id: 4,
    img: "./images/news/news3.jpg",
    title: "The International Models Brands",
    category: "Brands ",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },

  {
    id: 5,
    img: "./images/news/news3.jpg",
    title: "The International Models Brands",
    category: "Brands ",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },

  {
    id: 6,
    img: "./images/news/news3.jpg",
    title: "The International Models Brands",
    category: "Brands ",
    desc: "the premium model agency webapplication has been named the best models agency app in the world, this awards was presented",
    authorName: "David Mark",
    authorAvatar: "./assets/author1.png",
    createdAt: "June 03, 2021",
  },
];
